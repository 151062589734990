import React from "react";
import "./Footer.css";
export default function Footer() {
  return (
    <div className="footer">
      <a href="https://github.com/mattachea/portfolioWebsite">
        Designed and Built by Matthew Chea.
      </a>
    </div>
  );
}
